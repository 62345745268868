import React, {useEffect, useState} from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import seed from '../data/seed'
import ReactTooltip from 'react-tooltip'

const mainColor = '#0123da';

const CancelToken = axios.CancelToken;
let cancel;

let displayPOS = [
    {posPrefix: 'v', display: 'verb', color: '#8bc34a'},
    // {posPrefix: 'adv', display: 'adverb', color: 'green'},
    {posPrefix: 's', display: 'substantiv', color: '#7474de'},
    {posPrefix: 'adj', display: 'adjectiv', color: 'green'},
    {posPrefix: 'pron', display: 'pronume', color: 'orange'},
    {posPrefix: 'num', display: 'numeral', color: 'orange'},
    // {posPrefix: 'prep', display: 'prepoziție', color: 'orange'},
    // {posPrefix: 'conj', display: 'conjuncție', color: 'orange'},
    // {posPrefix: 'interj', display: 'interjecție', color: 'darkgreen'},
    // {posPrefix: 'loc', display: 'locuțiune', color: 'darkgreen'},
    // {posPrefix: 'e', display: 'expresie', color: 'orange'},
];

let displayPOSByPrefix = displayPOS.reduce((total, currentValue, currentIndex, arr) => {
    total[currentValue.posPrefix] = currentValue;
    return total;
}, {});

const ExercitiuPage = (props) => {

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(!!props.data);
    const [text, setText] = useState(props.text || seed.shortText);
    const [data, setData] = useState(props.data || []);
    const [activePOS, setActivePOS] = useState({'v': true});
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(0);
    const [cnt, setCnt] = useState(0);
    const [showResults, setShowResults] = useState(false);

    useEffect(() => {
        fetchData();
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

    useEffect(() => {
        ReactTooltip.rebuild()
    });

    const fetchData = () => {
        if (data && data.length > 0) {
            console.log("Already loaded");
            return;
        }
        setLoading(true);
        setError(null);
        setData([]);
        if (cancel !== undefined) {
            console.log('canceling req');
            cancel();
        } else {
            console.log('no req to cancel');
        }
        axios
            .post(
                // `http://localhost:3000/grammar/` ,
                `https://www.analiza-gramaticala.ro:4443/grammar/` ,
                {text},
                {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                        console.log('received cancel token ', cancel, c)
                    })
                }
            ).then(({ data }) => {
                console.log('response', data);
                setLoading(false);
                setLoaded(true);
                setData(data.output);
                setError(null);
            })
            .catch(error => {
                console.error('err', error);
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                } else {
                    if (error && error.response && error.response.status) {
                        setErrorCode(error.response.status);
                    }
                    setLoading(false);
                    setError(error);
                }
            });
    }

    let btnStyleBase = {
        margin: '5px',
        border: '1px gray solid',
        padding: '6px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    }
    let btnStyleSelected = Object.assign({}, btnStyleBase, {
        background: mainColor,
        color: 'white',
        display: 'inline-block',
    });
    let btnStyleUnselected = Object.assign({}, btnStyleBase, {
        background: 'white',
        color: mainColor,
        display: 'inline-block',
    });
    const onPosClick = (dp) => {
        let oldState = activePOS[dp.posPrefix];
        if (oldState) {
            return; // nothing to do
        }
        data.forEach(e => e.selected = false);
        setActivePOS({[dp.posPrefix]: !oldState});
        setShowResults(false);
    }
    const renderDisplayPOS = (dp) => {
        return (
            <span onClick={() => onPosClick(dp)} style={activePOS[dp.posPrefix] ? btnStyleSelected : btnStyleUnselected}>
                {dp.display}
            </span>
        )
    }

    const describePos = (e) => {
        if (!e || !e.posDesc) {
            return null;
        }
        let posDesc = e.posDesc;
        if (posDesc.length <= 1) {
            return posDesc.join(', ');
        }
        return "indecis: " + posDesc.slice(0, -1).join(', ') + ' sau ' + posDesc.slice(-1)[0];
    };
    const renderItem = (e) => {
        let def = {
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        };
        if (e.word !== ' ' && !e.word.endsWith(' ')) {
            def.display = 'inline-block';
        }
        if (e.word === '\n') {
            return <p/>
        }

        if (!showResults) {
            let onItemClick = () => {
                e.selected = !e.selected;
                setCnt(cnt + 1);
            }

            if (e.selected) {
                var color = 'black';
                var s = Object.assign({}, def, {
                    border: '1px solid  ' + color,
                    color: 'black',
                    padding: '3px',
                    borderRadius: '15px'
                })
                return <span style={s} onClick={onItemClick}>{e.word}</span>
            }
            // let style = hl && (e.ambig === 'NO' || e.ambig === 'YES') ? {backgroundColor: 'orange'} : {};
            return <span onClick={onItemClick}>{e.word}</span>
        }

        let activePOSUnique = Object.keys(activePOS)[0]
        let hl = (e.pos || []).find(p => activePOS[p]);
        let posDesc = describePos(e);
        if (!hl) {
            if (e.selected) {
                var s =  Object.assign({}, def, {
                    border: (e.ambig === 'NO' ? '2px solid ' : '2px dashed ') + ' red',
                    color: 'black',
                    padding: '3px',
                    borderRadius: '15px'
                })
                return <span  data-tip={posDesc} style={s}>{e.word}</span>
            }
            return <span data-tip={posDesc}>{e.word}</span>;
        }

        var color;
        if (e.selected) {
            color = (e.pos || []).indexOf(activePOSUnique) >= 0 ? 'green' : 'darkred';
        } else {
            color = (e.pos || []).indexOf(activePOSUnique) >= 0 ? 'darkred' : 'green';
        }

        var s =  Object.assign({}, def, {
            border: (e.ambig === 'NO' ? '2px solid ' : '2px dashed ') + color,
            color: 'black',
            padding: '3px',
            borderRadius: '15px'
        })
        return <span  data-tip={posDesc /*+ e.pos + "/" + activePOSUnique*/} style={s}>{e.word}</span>


    }

    return <div>
        {/*<Layout  location={props.location}>*/}

        {/*<SEO title="Exerciții" description="Analiză gramaticală pentru limba română. Verbe, substantive, adjective, adverbe"/>*/}


        <div> <h3>Exercițiu analiză <div><i>{props.author}, {props.title}</i></div></h3> </div>

        {loading && <div>
            <div>{props.text}</div>
            <br/>
            <div><i>procesare...</i></div>
        </div>}

        {error && <div>
            <div>{props.text}</div>
            <br/>
            <div style={{color: 'red',}}><b>Erorare la procesarea textului: {error + ""}</b></div>
        </div>}


        {loaded && [
            <div style={{fontSize: 'smaller'}}>
                <i>Selectați una dintre aceste categorii apoi marcați (cu click) cuvintele din acea categorie.</i>
            </div>
            ,

            <div style={{flex: '1', paddingBottom: '21px', fontSize: 'smaller'}}>
                {displayPOS.map(dp => renderDisplayPOS(dp))}
            </div>
            ,
            <div>
                {data.map(crt => renderItem(crt))}
            </div>
            ,
            <br/>
            ,
            <input type={'button'} value={showResults? 'Ascunde rezultatele' : 'Arată rezultatele'} onClick={()=> {setShowResults(!showResults)}} style={{background: mainColor, color: 'white',}}/>
            ,
            <br/>,
            <br/>,
            <div style={{float: 'right',}}><i>Comunică-ne părerea ta pe <a href="https://forum.analiza-gramaticala.ro/" target={"forum"}>forum</a></i></div>,
            <br/>,
            <br/>,
            <ReactTooltip place="bottom"/>,
        ]}


    {/*</Layout>*/}
        </div>
}

export default ExercitiuPage
