import React, {useEffect, useState} from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import seed from '../data/seed'
import ReactTooltip from 'react-tooltip'
import ExercitiuPage from "./ExercitiuPage";

const mainColor = '#0123da';

const CancelToken = axios.CancelToken;
let cancel;

let displayPOS = [
    {posPrefix: 's', display: 'substantiv', color: '#7474de'},
    {posPrefix: 'adj', display: 'adjectiv', color: 'green'},
    {posPrefix: 'pron', display: 'pronume', color: 'orange'},
    {posPrefix: 'num', display: 'numeral', color: 'orange'},
    {posPrefix: 'v', display: 'verb', color: '#8bc34a'},
    {posPrefix: 'adv', display: 'adverb', color: 'green'},
    {posPrefix: 'prep', display: 'prepoziție', color: 'orange'},
    {posPrefix: 'conj', display: 'conjuncție', color: 'orange'},
    {posPrefix: 'interj', display: 'interjecție', color: 'darkgreen'},
    {posPrefix: 'loc', display: 'locuțiune', color: 'darkgreen'},
    {posPrefix: 'e', display: 'expresie', color: 'orange'},
];

let displayPOSByPrefix = displayPOS.reduce((total, currentValue, currentIndex, arr) => {
    total[currentValue.posPrefix] = currentValue;
    return total;
}, {});

const RulesPage = (props) => {
    // console.log('RulesPage', props)

    const [data, setData] = useState(props.exercitii || seed.exercitii);
    const [selected, setSelected] = useState();



    function summary(text) {
        if (text.length < 100) {
            return text;
        }
        return text.substring(0, 99) + "...";
    }

    return <Layout location={props.location}>

        <SEO title="Exerciții"
             description="Analiză gramaticală pentru limba română. Verbe, substantive, adjective, adverbe"/>


        {!selected && <div>
            <h3>Exerciții</h3>
            <b>
                Alegeți un exercițiu
            </b>
            <br/>
            <br/>
            <p>
            </p>
            {data.map(e => (<div onClick={() => setSelected(e)} style={{cursor: 'pointer',}}>
                    <b>{e.title} - {e.author} </b>
                    <p>{summary(e.text)}</p>
                    <br/>
                </div>
            ))}
        </div>
        }

        {selected && <div>
            <button onClick={() => setSelected(undefined)} style={{float: 'right', cursor: 'pointer',}}>Înapoi la lista de exerciții</button>
            <ExercitiuPage author={selected.author} title={selected.title} text={selected.text}/>
            <button onClick={() => setSelected(undefined)} style={{cursor: 'pointer',}}>Înapoi la lista de exerciții</button>
        </div>}
    </Layout>;
}

export default RulesPage
